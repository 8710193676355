import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CrudService } from '../../shared/crud/crud.service';  // CRUD API service class
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { Location } from '@angular/common';  // Location service is used to go back to previous component


@Component({
	selector: 'app-company-edit',
	templateUrl: './company-edit.component.html',
	styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
	editForm: FormGroup;  // Define FormGroup to student's edit form
	constructor(
		public authService: AuthService,
		public router: Router,
		public ngZone: NgZone, // Toastr service for alert message
		public crudApi: CrudService,

		private fb: FormBuilder,            // Inject Form Builder service for Reactive forms
		private location: Location,         // Location service to go back to previous component
		private actRoute: ActivatedRoute,   // Activated route to get the current component's inforamation
		private toastr: ToastrService       // Toastr service for alert message
	) { }

	ngOnInit() {
		this.updateCompanyData();   // Call updateStudentData() as soon as the component is ready 
		const id = this.actRoute.snapshot.paramMap.get('id');  // Getting current component's id or information using ActivatedRoute service
		this.crudApi.GetCompany(id).valueChanges().subscribe(data => {
			if(!data.urlCompany) data.urlCompany = '';
			if(!data.nameContact) data.nameContact = '';
			if(!data.phoneCompany) data.phoneCompany = '';
			if(!data.serverName) data.serverName = '';
			if(!data.urlServer) data.urlServer = '';
			if(!data.loginServer) data.loginServer = '';
			if(!data.passwordServer) data.passwordServer = '';
			if(!data.emailInfo) data.emailInfo = '';
			if(!data.generalInfo) data.generalInfo = '';
		this.editForm.setValue(data)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
		})
	}

	// Accessing form control using getters
	// get firstName() {
	// 	return this.editForm.get('firstName');
	// }

	// get lastName() {
	// 	return this.editForm.get('lastName');
	// }

	// get email() {
	// 	return this.editForm.get('email');
	// }

	// get mobileNumber() {
	// 	return this.editForm.get('mobileNumber');
	// }

	// Contains Reactive Form logic
	updateCompanyData() {
		this.editForm = this.fb.group({
			companyName: ['', [Validators.required, Validators.minLength(2)]],
			urlCompany: [''],
			nameContact: [''],
			phoneCompany: [''],
			serverName: [''],
			urlServer: [''],
			loginServer: [''],
			passwordServer: [''],
			emailInfo: [''],
			generalInfo: [''],
			visible: true,
		})  
	}

	// Go back to previous component
	goBack() {
		this.location.back();
	}

	// Below methods fire when somebody click on submit button
	updateForm() {
		this.crudApi.UpdateCompany(this.editForm.value);       // Update student data using CRUD API
		this.toastr.success(this.editForm.controls['companyName'].value + ' updated successfully');   // Show succes message when data is successfully submited
		this.router.navigate(['company-list']);               // Navigate to student's list page when student data is updated
	}

	copyMessage(textarea: string = null){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
			if(textarea) selBox.value = (<HTMLElement>event.target).parentNode.querySelector('textarea').value;	
			else         selBox.value = (<HTMLElement>event.target).parentNode.querySelector('input').value;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		let eTarget = <HTMLTextAreaElement>event.target;
		eTarget.classList.add('copied');
		setTimeout(() => {
		  eTarget.classList.remove('copied');
		}, 800);
	  }
}
