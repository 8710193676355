import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '../../shared/crud/company';   // Student interface class for Data types.

@Pipe({ name: 'filterByDescription' })
export class filterByDescription implements PipeTransform {

    transform(company: Company[], descriptionQuery: string) {
        descriptionQuery = descriptionQuery.trim().toLocaleLowerCase();
        if (descriptionQuery) {
            return company.filter(
                // company => company.companyName.toLocaleLowerCase().includes(descriptionQuery) || company.urlCompany.toLocaleLowerCase().includes(descriptionQuery)
                company => company.companyName.toLocaleLowerCase().includes(descriptionQuery)
            );
        } else {
            return company;
        }
    }

}