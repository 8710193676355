import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { CrudService } from '../../shared/crud/crud.service';  // CRUD API service class
import { Company } from '../../shared/crud/company';   // Student interface class for Data types.
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  p: number = 1;                      // Fix for AOT compilation error for NGX pagination
  Company: Company[];                 // Save students data in Student's array.
  hideWhenNoStudent: boolean = false; // Hide students data table when no student.
  noData: boolean = false;            // Showing No Student Message, when no student in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  filter: string = '';
  
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone, // Toastr service for alert message
    public crudApi: CrudService,
    private toastr: ToastrService       // Toastr service for alert message
  ) {
    
   }

  
   ngOnInit() {
    this.dataState(); // Initialize student's list, when component is ready
    let s = this.crudApi.GetCompaniesList(); 
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata($key)
      this.Company = [];
      data.forEach(item => {
        let eachCompany = item.payload.toJSON(); 
        eachCompany['$key'] = item.key;
        //se o cara nao tiver sido excluido
        if(eachCompany['visible'])
          this.Company.push(eachCompany as Company);
      })
    })
  }

  // Using valueChanges() method to fetch simple list of students data. It updates the state of hideWhenNoStudent, noData & preLoader variables when any changes occurs in student data list in real-time.
  dataState() {     
    this.crudApi.GetCompaniesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoStudent = false;
        this.noData = true;
      } else {
        this.hideWhenNoStudent = true;
        this.noData = false;
      }
    })
  }

  // Method to delete student object
  deleteStudent(student) {
    if (window.confirm('Are sure you want to delete this student ?')) { // Asking from user before Deleting student data.
      this.crudApi.DeleteCompany(student.$key) // Using Delete student API to delete student.
      this.toastr.success(student.companyName + ' deleted successfully');   // Show succes message when data is successfully submited
    }
  }

  copyMessage(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let eTarget = <HTMLTextAreaElement>event.target;
    eTarget.classList.add('copied');
    setTimeout(() => {
      eTarget.classList.remove('copied');
    }, 800);
  }

  
}