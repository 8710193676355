import { Component, OnInit, NgZone } from '@angular/core';
import { CrudService } from '../../shared/crud/crud.service';    // CRUD services API
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'; // Reactive form services
import { ToastrService } from 'ngx-toastr'; // Alert message using NGX toastr
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";


@Component({
	selector: 'app-company-add',
	templateUrl: './company-add.component.html',
	styleUrls: ['./company-add.component.scss']
})

export class CompanyAddComponent implements OnInit {
	public companyForm: FormGroup;  // Define FormGroup to student's form
	
	constructor(
		public fb: FormBuilder,       // Form Builder service for Reactive forms
		public toastr: ToastrService,  // Toastr service for alert message
		public authService: AuthService,
		public router: Router,
		public ngZone: NgZone, // Toastr service for alert message
		public crudApi: CrudService
		) { }
		
		
		ngOnInit() {
			this.crudApi.GetCompaniesList();  // Call GetStudentsList() before main form is being called
			this.companyFormInfo();              // Call student form when component is ready
		}
		
		// Reactive student form
		companyFormInfo() {
			this.companyForm = this.fb.group({
				companyName: ['', [Validators.required, Validators.minLength(2)]],
				urlCompany: [''],
				nameContact: [''],
				phoneCompany: [''],
				serverName: [''],
				urlServer: [''],
				loginServer: [''],
				passwordServer: [''],
				emailInfo: [''],
				generalInfo: [''],
				visible: true
				// email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
				// mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
			})  
		}
		
		// Accessing form control using getters
		get companyName() {
			return this.companyForm.get('companyName');
		}
		
		get urlCompany() {
			return this.companyForm.get('urlCompany');
		}  
		
		get nameContact() {
			return this.companyForm.get('nameContact');
		}
		
		get phoneCompany() {
			return this.companyForm.get('phoneCompany');
		}
		
		get serverName() {
			return this.companyForm.get('serverName');
		}
		
		get urlServer() {
			return this.companyForm.get('urlServer');
		}
		
		get loginServer() {
			return this.companyForm.get('loginServer');
		}
		
		get passwordServer() {
			return this.companyForm.get('passwordServer');
		}
		
		get emailInfo() {
			return this.companyForm.get('emailInfo');
		}
		
		get generalInfo() {
			return this.companyForm.get('generalInfo');
		}
		
		ResetForm() {
			this.companyForm.reset();
		}  
		
		submitStudentData() {
			this.crudApi.AddCompany(this.companyForm.value);
			this.toastr.success(this.companyForm.controls['companyName'].value + ' successfully added!');
			this.ResetForm();  // Reset form when clicked on reset button
			// this.router.navigate(['company-list']);       
		};
		
	}