import { Injectable } from '@angular/core';
import { Company } from './company';  // Student data type interface class
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object

@Injectable({
  providedIn: 'root'
})

export class CrudService {
  studentsRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  companyRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too
  
  // Inject AngularFireDatabase Dependency in Constructor
  constructor(private db: AngularFireDatabase) { }

  // Create Student
  AddCompany(company: Company) {
    this.studentsRef.push({
      companyName: company.companyName,
      urlCompany: company.urlCompany,
      nameContact: company.nameContact,
      phoneCompany: company.phoneCompany,
      serverName: company.serverName,
      urlServer: company.urlServer,
      loginServer: company.loginServer,
      passwordServer: company.passwordServer,
      emailInfo: company.emailInfo,
      generalInfo: company.generalInfo,
      visible: true
    })
  }

  // Fetch Single Student Object
  GetCompany(id: string) {
    this.companyRef = this.db.object('company-list/' + id);
    return this.companyRef;
  }

  // Fetch Students List
  GetCompaniesList() {
    this.studentsRef = this.db.list('company-list');
    return this.studentsRef;
  }  

  // Update Student Object
  UpdateCompany(company: Company) {
    this.companyRef.update({
      companyName: company.companyName,
      urlCompany: company.urlCompany,
      nameContact: company.nameContact,
      phoneCompany: company.phoneCompany,
      serverName: company.serverName,
      urlServer: company.urlServer,
      loginServer: company.loginServer,
      passwordServer: company.passwordServer,
      emailInfo: company.emailInfo,
      generalInfo: company.generalInfo
    })
  }  

  DeleteCompany(id: string) { 
    this.companyRef = this.db.object('company-list/' + id);
    // this.companyRef.remove();  //remove
    this.companyRef.update({visible: false});  //soft delete
  }
  
}