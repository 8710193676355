import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
@Injectable({providedIn: 'root'})
export class MenuComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) { }

  ngOnInit() {
  }

  handleMenuMobile() {
    let eTarget = <HTMLTextAreaElement>event.target;
    let menu = <HTMLTextAreaElement>document.querySelector('.sidebar');
    if (menu.offsetWidth == 0) {
      menu.style.width = '300px';
      document.querySelector('.button__menu_responsive').classList.add('open');
      return;
    }
    document.querySelector('.button__menu_responsive').classList.remove('open');
    menu.style.width = '0px';
  }
}