import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  
  constructor(
    public authService: AuthService
    ) { }
    
    ngOnInit() {
      var c = 0;
      var tru = true;
      let bodyT = document.querySelector('body');
      bodyT.addEventListener('keyup', function (e) {
        if(e.key == 'c') {
          if(c < 3) c++;
        };
        if(c == 3 && tru) {
          document.querySelector('.js-form-login').classList.remove('display-none');
        }
        
        // });
        // setTimeout(() => {
        //   if(c != 3) {
        //     tru = false;
        //     setInterval(() => {
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //       cornify_add();return false;
        //     }, 1);
        //   }
        // }, 3000);
      });
    }
    
  }